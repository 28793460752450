<template>
  <div class="sm:w-2/5 animated reveal">
    <div class="flex justify-between">
      <div class="mb-8 text-xl font-bold sm:text-2xl text-invest-primary">
        My bank accounts
      </div>
    </div>

    <bank-accounts
      @bankAccounts="bankAccounts"
      :accounts="accounts"
      :loading="loading"
    />
    <create-account
      ref="createAccount"
      @bankAccounts="bankAccounts"
      :key="componentKey"
    />
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  ref,
  onBeforeMount,
  nextTick,
} from 'vue';
import { getBankAccounts } from '@/modules/requests';
import { AccountProp } from '@/types/Account';

export default defineComponent({
  components: {
    BankAccounts: defineAsyncComponent(
      () => import('@/components/account/BankAccounts.vue'),
    ),
    CreateAccount: defineAsyncComponent(
      () => import('@/components/account/CreateAccount.vue'),
    ),
  },
  setup: () => {
    const loading = ref<boolean>(false);
    const accounts = ref<AccountProp[]>([]);
    const createAccount = ref<any>(null);

    const componentKey = ref<number>(0);

    const bankAccounts = () => {
      loading.value = true;
      getBankAccounts()
        .then((response) => (accounts.value = response?.data))
        .catch((error) => console.log(error))
        .finally(() => (loading.value = false));
    };

    onBeforeMount(() => bankAccounts());

    const create = () => {
      componentKey.value++;
      nextTick(() => createAccount.value.open());
    };

    return {
      bankAccounts,
      loading,
      accounts,
      componentKey,
      createAccount,
      create,
    };
  },
});
</script>
